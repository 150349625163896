import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "../../../ui/CircularProgress";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: '1px solid #eee',
    borderRadius: 8,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    width: '50%',
  },
  text: {
    padding: theme.spacing(3)
  }
}));

function ScoreProgress(props){
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <CircularProgress value={props.value} text={props.value} class={props.color} size={100}/>
      <div className={classes.text}>
        <Typography variant="subtitle1" color="textPrimary">
          <strong>{props.text} {props.value}%</strong>
        </Typography>
      </div>  
    </div>
  );



}

export default ScoreProgress;
