import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import './survey.css'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: -20,
    marginBottom: theme.spacing(9),
    padding: theme.spacing(2),
    align:"center",
    variant: "outlined",
    transition: "all 1s",
    opacity: "1",
    animation: 'fadeIn ease 1.5s',
    animationIterationCount: 1,
    animationFillMode: 'forwards'    
  },
  progressText: {
    width: '117px',
    fontWeight: 'bold',
    margin: theme.spacing(1),
    display: 'inline-block',
  },
  progressBarContainer: {
    width: 'calc(100% - 172px)',
    display: 'inline-block',
  },
  progressStats: {
    width: '23px',
    fontWeight: 'bold',
    margin: theme.spacing(1),
    display: 'inline-block',
  },
  option: {
    width: "80%",
    [theme.breakpoints.down('md')]: {
      height: 80,
    },
    [theme.breakpoints.up('lg')]: {
      height: 140,
    },
    margin: '20px 10% 20px 10%',
    padding: 20,
    textTransform: 'none',
  },
  emoji: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 12,
      left: 12,
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 12,
      left: 0,
      width: '100%',
    },

    height: 48,
    margin: 'auto',
  },
  optionText: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      left: 72,
      textAlign: 'left',
      display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      width: 'calc(90% - 72px)',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 72,
      left: '10%',
      width: '80%',
      height: 40,
    },    
  },
  questionText: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    display: 'inline-block',
  },
  optionGroup: {
    paddingTop: 30,
    marginBottom: 12,
    width:'100%',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  justify: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#E0ECF4',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0066A8',
  },
}))(LinearProgress);

function Question(props) {
  const data = props.data;
  const classes = useStyles();
  const answers = [data.answer0, data.answer1, data.answer2, data.answer3];
  const [selection, setSelection] = React.useState(props.currentAnswers[data.id]);

  const prevQuestion = () => {
    props.setCurrentQuestion(props.arrayId - 1);
  }

  const nextQuestion = () => {
    props.setCurrentQuestion(props.arrayId + 1);
  }

  const selectButton = (index) => {
    setSelection(index);
    props.changeFunction(index);
    if(props.arrayId < props.surveyLength-1){
      nextQuestion();
    }
  }

  const emojiSrc = ["excellent", "good", "normal", "bad"];

  return (
    <Paper elevation={3} className={classes.card} >
      
      <Typography variant="body1" color="textPrimary" className={classes.progressText}>
        Your Progress:
      </Typography>
      <div className={classes.progressBarContainer}>
        <BorderLinearProgress variant="determinate" value={(props.arrayId+1)/props.surveyLength*100} />
      </div>
      <Typography variant="body1" color="textPrimary" className={classes.progressStats}>
        {(props.arrayId+1)}/{props.surveyLength}
      </Typography>
      
      <Divider />

      <Typography variant="h6" color="textPrimary" className={classes.questionText}>
        <span style={{color: '#0066A8'}}>Question {props.arrayId+1}.</span> {data.question_text}
      </Typography>
      <Grid container className={classes.optionGroup}>
        {answers.map((answer, index) => {
          return (
            <Grid key={answer} item container spacing={3} xs={12} sm={6} lg={3} >
              <Button variant="outlined" value={index} style={selection === index ? {backgroundColor: "#eeeeff"} : {}} className={classes.option} onClick={() => selectButton(index)}>
                <img src={"/assets/emoji/" + emojiSrc[index] + "Emoji.svg"} alt={"numberingForAnswers"} className={classes.emoji} />
                <Typography className={classes.optionText} >
                  {answer}
                </Typography>
              </Button>
            </Grid>
          )
        })}
      </Grid>
      <div className={classes.justify}>
        {props.arrayId !== 0 ? (
        <Fab className={classes.button} variant="extended" onClick={prevQuestion}>
          <ArrowBackIcon />
          Prev
        </Fab>) : <div />}
        {props.arrayId !== props.surveyLength-1 && (
        <Fab className={classes.button} disabled={typeof(selection) === 'undefined'} variant="extended" onClick={nextQuestion}>
          Next
          <ArrowForwardIcon />
        </Fab>
        )}
      </div>
    </Paper> 
  );
}

export default Question
