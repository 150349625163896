import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataStore } from '../../api/DataStore';
import { submitSurvey, currentAnswers } from '../../state/reducers/data.js';
import Question from './Question';
import Container from '@material-ui/core/Container';

function Survey() {
  const dispatch = useDispatch();
  const API = useDataStore();

  const questions = useSelector(state => state.data.questions);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isDataRecieved, setIsDataRecieved] = useState(false);
  const [answers, setAnswers] = useState({})


  function handleChange(id, value, data){
    let newAnswers = {
      ...answers,
      [id]: value,
    }
    setAnswers(newAnswers);

    dispatch(currentAnswers(Object.keys(newAnswers).length))

    if(currentQuestion === questions.length-1){
      handleSubmit(newAnswers);
    }
  }

  function handleSubmit(answersToSend){
    dispatch(submitSurvey(answersToSend))
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if(isDataRecieved){
      return;
    }
    API.setupSurvey();
    setIsDataRecieved(true);
  }, [isDataRecieved, API]);

  const renderQuestion = (index) => {
    const question = questions[index];
    if(typeof question !== 'undefined'){
      return(
        <Question
        data={question}
        key={question.id}
        changeFunction={(value) => handleChange(question.id, value, question)}
        arrayId={index}
        currentAnswers={answers}
        surveyLength={questions.length}
        setCurrentQuestion={setCurrentQuestion}
        />
      )
    }
  }
  
  return (
      <Container style={{maxWidth: 1140}}>
        <div>
          {renderQuestion(currentQuestion)}
        </div>
      </Container>
  );
}

export default Survey
