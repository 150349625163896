export function calculateRecommendations(answers, mappings){
  let recommendations = [];
  for(let r in mappings){
    let data = mappings[r];
    let total = 0;
    let max = 0;
    let scaledTotal = 0;
    for(let m in data.recommendation_mappings){
      let map = data.recommendation_mappings[m];
      total += answers[map.question]*map.score*data.weight;
      max += 3*map.score*data.weight;
    }
    scaledTotal = (total * (100/max)).toFixed(0);
    recommendations.push({id: data.id, title: data.title, score: 100-scaledTotal, industry_average: data.industry_average});
  }
  const sorted = [...recommendations].sort((a,b) => a.score - b.score);
  return sorted;
}
