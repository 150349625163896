import axios from 'axios';
import { setEmailFailed, setSurvey, setParticipant, setFullRecommendations } from '../state/reducers/data';
import { setIsLoading } from '../state/reducers/loading';

//////////////////////////////////////////
import { testSurveys } from './testData';
const testData = false;
if(testData) console.log("USING TEST DATA!");
console.log(testSurveys);
//////////////////////////////////////////

const headers = {
  'Content-Type': 'application/json',
};
axios.defaults.headers = headers;
axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/survey/"

export function initialiseAPI(dispatch) {
  axios.interceptors.request.use((config) => {
    dispatch(setIsLoading(true));
    return config;
  });

  axios.interceptors.response.use((response) => {
    dispatch(setIsLoading(false));
    return response;
  });

  return {
    setupSurvey: async () => {
      if(testData){
        dispatch(setSurvey(testSurveys[0]));
        return
      }
      const response = await axios.get('surveys');
      dispatch(setSurvey(response.data.find(survey => survey.id === process.env.REACT_APP_SURVEY_ID)))
    },
    sendAnswers: async (surveyId, results) => {
      const response = await axios.post('participant/', {
        survey: surveyId,
        email: "",
        country: "",
        phone: "",
        results: JSON.stringify(results),
      });
      dispatch(setParticipant(response.data));
    },
    sendEmail: async (participant, email, captchaValue) => {
      await axios.put('participant/' + participant.id + '/', { email: email, id: participant.id });
      try {
        await axios.get('send_link/' + participant.id + '/' + captchaValue);
        dispatch(setEmailFailed(false));
      } catch (err) {
        dispatch(setEmailFailed(true));
      }
    },
    getParticipantData: async (id) => {
      const response = await axios.get('participant/' + id);
      dispatch(setParticipant(response.data));
      const results = JSON.parse(response.data.results);
      let fullRecommendations = [];
      for (let r in results.recommendations) {
        let rec = results.recommendations[r];
        let recResponse = await axios.get('full-recommendations/' + rec.id);
        fullRecommendations.push({ ...recResponse.data, score: rec.score, index: parseInt(r)})
      }
      dispatch(setFullRecommendations(fullRecommendations))
    },
  }
}
