import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './reducers/data';
import loadingReducer from './reducers/loading';
import dialogReducer from './reducers/dialog';

export const store = configureStore({
  reducer: {
    data: dataReducer,
    loading: loadingReducer,
    dialog: dialogReducer,
  },
});
