import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { selectCategory } from '../../state/reducers/data.js';
import Typography from '@material-ui/core/Typography';
import NestedScoreProgress from './parts/NestedScoreProgress';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#F5F9FC'
    },
    fontWeight: 600,
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
  },
  flex: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    height: '100%',
  },
}));

function CategoryItem(props){
  const selectedCategory = useSelector(state => state.data.selectedCategory);
  const data = props.data;
  const dispatch = useDispatch();

  const classes = useStyles();
  const style = (selectedCategory === props.index) ? {backgroundColor:"#FEF9F7", boxShadow: 'inset 0px 0px 5px #9d9d9d'} : {}

  return (
    <div className={classes.root} style = {style} onClick={() => dispatch(selectCategory(props.index))}>
      <div className={classes.flexColumn}>
        <Typography variant="h6" color="textPrimary">
          {("0" + (props.index + 1)).slice(-2)}. {data.title}
        </Typography>
        <br />
        <NestedScoreProgress data={data} />
      </div>
      <ArrowForwardIosIcon style={{color: '#BAC4CC'}}/>
    </div>
  );
}

export default CategoryItem;
