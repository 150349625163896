import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { nextCategory, prevCategory } from '../../../state/reducers/data.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ScoreProgress from './ScoreProgress';
import NestedScoreProgress from './NestedScoreProgress';
import EmailForm from './EmailForm';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 650,
    },
    animation: 'fadeIn ease 1.5s',
    animationIterationCount: 1,
    animationFillMode: 'forwards'
  },
  flex: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: "row",
  },
  number: {
      backgroundColor: '#EBF3F8',
      color: '#0066A8',
      padding: 5,
      marginRight: 5,
  },
  nestedScoreContainer: {
      display: 'flex',
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: '1px solid #eee',
      borderRadius: 8,
      margin: theme.spacing(1),
      padding: theme.spacing(2),
  },
  orangeBox: {
      backgroundColor: '#FEF9F7',
      color: '#ED722E',
      margin: theme.spacing(1),
      padding: theme.spacing(3),
      borderRadius: 10,
  },
  bodyContainer: {
      padding: theme.spacing(1),
  },
  blueBox: {
      backgroundColor: '#EBF3F8',
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: 10,
  },
  justify: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },

}));

function BodyCard (props) {

    let data = {...props.data, conclusion: ''};
    const classes = useStyles();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const tabletMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const parser = (str) => (str) ? parse(str) : "" //checks if parsed value is not undefined
    
    if (data.score >= 90){
      data.conclusion = data.good
    } else if (data.score >= data.industry_average){
        data.conclusion = data.weak
    } else {
        data.conclusion = data.poor
    }

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    return(
        <Card elevation={3} className={classes.root}>
            <div className={classes.header}>
                <Avatar className={classes.number}>{("0" + (data.index + 1)).slice(-2)}</Avatar>
                <Typography variant="h5" color="textPrimary" style={{padding: 8}} >
                    {data.title}
                </Typography>
            </div>
            {isMobile ? (
            <div className={classes.nestedScoreContainer}>
                <NestedScoreProgress data={data} />
            </div>
            ) : (
            <div className={classes.flex}>
                <ScoreProgress value={data.score} color={"orange"} text="Your score:"/>
                <ScoreProgress value={data.industry_average}  color={"blue"} text="Industry average:"/>
            </div>
            )}
           
            <div className={classes.orangeBox}>
                <Typography variant="h6" color="textPrimary">
                    <span style={{color: '#ED722E'}}>Conclusion:</span>
                </Typography>
                <span className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorTextPrimary" >
                    {parser(data.conclusion)}
                </span>
            </div>
            <div className={classes.bodyContainer} scroll={"paper"} >
                {id ? (
                    <div>
                        <span className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary" >
                            {parser(data.description)}
                        </span>
                        <div className={classes.blueBox}>
                            <span className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorTextPrimary" >
                                {parser(data.call_to_action)}
                            </span>
                            <div className={classes.flex}>
                              <Fab className={classes.button} variant="extended">
                                  <a style={{color: '#fff', textDecoration: 'none'}} href={data.cta_button_link}>{data.cta_button_text}</a>
                              </Fab>
                            </div>
                        </div>
                    </div>
                ) : (
                    <EmailForm />
                )}
            </div>
            {!tabletMobile && (
                <div className={classes.justify}>
                  {data.index !== 0 ? (
                  <Fab className={classes.button} variant="extended" onClick={() => dispatch(prevCategory())}>
                    <ArrowBackIcon />
                    Prev
                  </Fab>) : <div />}
                  {data.index !== props.maxIndex && (
                  <Fab className={classes.button} variant="extended" onClick={() => dispatch(nextCategory())}>
                    Next
                    <ArrowForwardIcon />
                  </Fab>
                  )}
                </div>
            )}
        </Card>
    )
}

export default BodyCard