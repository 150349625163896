import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  orange: {
    color: '#ED722E!important',
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
  },
  blue: {
    color: '#0066A8!important',
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
  },
  grey: {
    color: '#E2EDF5!important',
    position: 'absolute',
    left: 0,
  }

}));

export const NestedCircularProgress = (props) => {
  const classes = useStyles();
  return (
  <Box position="relative" display="inline-flex" style={{height: props.size}} >
    <CircularProgress variant="determinate" value={100} size={props.size} className={classes["grey"]}/>
    <CircularProgress variant="determinate" value={props.value} size={props.size} className={classes[props.class]}/>
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CustomCircularProgress size={props.innerSize} value={props.innerValue} text={props.value} class={props.innerClass} thickness={2} />
    </Box>
  </Box>
)}

const CustomCircularProgress = (props) => {
  const classes = useStyles();
  return (
  <Box position="relative" display="inline-flex" style={{height: props.size}}>
    <CircularProgress variant="determinate" value={100} size={props.size} className={classes["grey"]} thickness={props.thickness}/>
    <CircularProgress variant="determinate" value={props.value} size={props.size} className={classes[props.class]} thickness={props.thickness}/>
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h6"
        component="div"
        color="textPrimary"
      >{`${Math.round(props.text)}%`}</Typography>
    </Box>
  </Box>
)}

export default CustomCircularProgress