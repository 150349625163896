export const testSurveys = [
    {
        "id": "90ab7511-b61f-47d3-8810-39b4c4df6d9d",
        "name": "IT Health Check",
        "introduction": "<p>Is your business valuation hampered by problems in Product and Technology. We&#39;ll help you find out using our survey.</p>",
        "start_button_text": "Start",
        "step1": "<p>Find out where technology could fit into your business and improve your performance.</p>",
        "step2": "<p>Find gaps in your business&#39;s digital performance.</p>",
        "step3": "<p>Get the advanced support you need to take the next step.</p>",
        "questions": [
            {
                "id": 1,
                "question_text": "Have you had any system outages in the last year?",
                "answer0": "No",
                "answer1": "Some but no customer impacts",
                "answer2": "Minor customer impacts",
                "answer3": "Major customer impact"
            },
            {
                "id": 2,
                "question_text": "Do you have regular meetings with the IT team?",
                "answer0": "Yes",
                "answer1": "If anything, too many",
                "answer2": "Yes, but only ones I organise",
                "answer3": "No"
            },
            {
                "id": 3,
                "question_text": "Are IT developing your systems with sufficient velocity and urgency?",
                "answer0": "Yes",
                "answer1": "Yes, but slowing",
                "answer2": "Slow, but steady",
                "answer3": "No"
            },
            {
                "id": 4,
                "question_text": "Do you have a clear delivery plan for the next 18 months?",
                "answer0": "Yes",
                "answer1": "Only for the next 6 months",
                "answer2": "Only for the next release",
                "answer3": "No"
            }
        ],
        "recommendations": [
            {
                "id": "57a21570-4d5a-4e40-a0eb-4eeada6398c9",
                "title": "Write a BCP plan",
                "weight": 1.0,
                "industry_average": 80,
                "recommendation_mappings": [
                    {
                        "question": 1,
                        "score": 2
                    }
                ]
            },
            {
                "id": "4074f534-6616-4369-a3fa-f35b16829ed1",
                "title": "Velocity",
                "weight": 12.0,
                "industry_average": 12,
                "recommendation_mappings": [
                    {
                        "question": 2,
                        "score": 4
                    },
                    {
                        "question": 4,
                        "score": 4
                    }
                ]
            },
            {
                "id": "b32fbd65-ed88-4f9b-9414-df46125445e2",
                "title": "Regular meetings",
                "weight": 1.0,
                "industry_average": 50,
                "recommendation_mappings": [
                    {
                        "question": 2,
                        "score": 3
                    },
                    {
                        "question": 1,
                        "score": 1
                    },
                    {
                        "question": 3,
                        "score": 2
                    },
                    {
                        "question": 4,
                        "score": 1
                    }
                ]
            },
            {
                "id": "7538e4bb-af0c-47ab-9bde-6d67d446c236",
                "title": "Backup your data now",
                "weight": 2.0,
                "industry_average": 50,
                "recommendation_mappings": [
                    {
                        "question": 1,
                        "score": 3
                    }
                ]
            },
            {
                "id": "47a52d72-8e66-4af8-bbd5-5d554eace7bc",
                "title": "Migrate to Cloud",
                "weight": 1.0,
                "industry_average": 50,
                "recommendation_mappings": [
                    {
                        "question": 3,
                        "score": 1
                    }
                ]
            },
            {
                "id": "0e86e724-edf5-47de-b7cf-57b843731e96",
                "title": "Product Roadmap",
                "weight": 2.0,
                "industry_average": 50,
                "recommendation_mappings": [
                    {
                        "question": 4,
                        "score": 3
                    }
                ]
            }
        ]
    }
]