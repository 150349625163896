import { Provider } from 'react-redux'
import { store } from '../state/store'
import { DataStore } from '../api/DataStore';
import MainPage from './MainPage'

function App() {
  return (
    <Provider store={ store }>
      <DataStore>
        <MainPage />
      </DataStore>
    </Provider>
  );
}

export default App;
