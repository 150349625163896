import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDataStore } from '../../api/DataStore';
import BodyCard from './parts/BodyCard';

function BodyText () {

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    const [data, setData] = useState({})

    const API = useDataStore();
    const [isDataRecieved, setIsDataRecieved] = useState(false);
    const participant = useSelector(state => state.data.participant);
    useEffect(() => {
        if(isDataRecieved || !participant) return;
        API.getParticipantData(participant.id);
        setIsDataRecieved(true);
    }, [isDataRecieved, API, participant]);

    useEffect(() => {
        if(isDataRecieved || !id) return;
        API.getParticipantData(id);
        setIsDataRecieved(true);
    }, [isDataRecieved, API, id]);

    const fullRecommendations = useSelector(state => state.data.fullRecommendations);
    const selectedCategory = useSelector(state => state.data.selectedCategory);

    useEffect(() => {
        if(fullRecommendations.length === 0) return;
        setData({...(fullRecommendations[selectedCategory])})
    }, [selectedCategory, fullRecommendations])

    const theme = useTheme();
    const tabletMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if(tabletMobile){
        return (
            <div>{fullRecommendations.map(rec => (<BodyCard key={rec.id} data={rec} />))}</div>
        )
    }

    return(
        <BodyCard data={data} maxIndex={fullRecommendations.length-1} />
    )
}

export default BodyText