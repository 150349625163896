import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { initialiseAPI } from './api';

const DataStoreContext = createContext({});

export function DataStore({ children }) {
  const dispatch = useDispatch();
  const api = initialiseAPI(dispatch);

  return <DataStoreContext.Provider value={api}>{children}</DataStoreContext.Provider>;
}

export function useDataStore() {
  return useContext(DataStoreContext);
}
