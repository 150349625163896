import {useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  upperSectionContainer: {
    paddingTop: '24px',
    
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
      height: 220,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '15%',
      paddingRight: '15%',
      height: 300,
    },
    
    alignItems: 'center',
    color: '#fff',
  },
  titleBarContainer: {
    height: 80,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 60,
    },    
    display: 'flex',
    flexDirection: 'row',
  },
  verticalDivider: {
    backgroundColor: '#8F9CA4',
    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
    [theme.breakpoints.up('sm')]: {
      height: 60,
    },
    margin: 10,
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: 46,
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  },
  headerText: {
    maxWidth: 363,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
    fontWeight: 400,
    color: '#8F9CA4',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 48,
    },
    fontWeight: 600,
  },
  button: {
    marginTop: 40,
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },
}));

function UpperSection() {
  const name = useSelector(state => state.data.name);
  const classes = useStyles();
  return (
    <div>
      <div className={classes.upperSectionContainer}>
        <div className={classes.titleBarContainer}>
          <a  href="http://thinkbluehat.com/" target="_blank" style={{cursor: "pointer"}}>
            <img src='/assets/BHA-logo.png' alt="BlueHatLogo" className={classes.logo}/>
          </a>
          <Divider orientation="vertical" className={classes.verticalDivider} />
          <Typography className={classes.headerText}>
            {name} Survey
          </Typography>
        </div>
        <Typography className={classes.title}>
          Results
        </Typography>
      </div>
    </div>
  );
}

export default UpperSection;
