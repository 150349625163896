import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NestedCircularProgress } from "../../../ui/CircularProgress";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    height: '100%',
    marginLeft: 16,
    marginRight: 8,
  },
}));

function NestedScoreProgress(props){
  const data = props.data;

  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <NestedCircularProgress value={data.score} class="orange" size={100} innerValue={data.industry_average} innerClass="blue" innerSize={80} />
      <div className={classes.flexColumn}>
        <Typography variant="subtitle1" color="textPrimary">
          <strong>Your Score: {data.score}%</strong>
        </Typography>          
        <Typography variant="subtitle1" color="textPrimary">
          <strong>Industry Average: {data.industry_average}%</strong>
        </Typography>
      </div>
    </div>
  );



}

export default NestedScoreProgress;
