import { createSlice } from '@reduxjs/toolkit';

const initialState = { emailOpen: false };

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setEmailDialogOpen(state, action) {
      state.emailOpen = action.payload;
    },
  },
});

export const { setEmailDialogOpen } = dialogSlice.actions;

export default dialogSlice.reducer;
