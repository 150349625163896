import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail } from '../../state/reducers/data.js';
import { setEmailDialogOpen } from '../../state/reducers/dialog.js';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },
  dialogActions: {
    '& > *':{
      margin: 10,
    },
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EmailDialog(props){
  const dispatch = useDispatch();
  const open = useSelector(state => state.dialog.emailOpen);
  const email = useSelector(state => state.data.tempEmail);

  const handleClose = () => {
    dispatch(setEmailDialogOpen(false))
  };

  const classes = useStyles();

  const reCaptchaSitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  function handleCaptchaVerification(value){
    if(value === null){
      return;
    }
    dispatch(sendEmail({email: email, captchaValue: value}));
    handleClose();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Submit Email Address"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Check you're not a robot and we'll send you an email.
        </DialogContentText>
      </DialogContent>
      
      <div className={classes.dialogActions}>
        <ReCAPTCHA
          style={{ display: "block" }}
          theme="light"
          ref={React.createRef()}
          sitekey={reCaptchaSitekey}
          onChange={handleCaptchaVerification}
        />
      </div>
    </Dialog>
  );

}

export default EmailDialog;
