import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setTempEmail } from '../../../state/reducers/data.js';
import { setEmailDialogOpen } from '../../../state/reducers/dialog.js';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';


const useStyles = makeStyles((theme) => ({
  flex: {
    borderRadius: 8,
    padding: theme.spacing(3),
    backgroundColor: '#EBF3F8',
  },
  emailField: {
    width: '100%'
  },
  justify: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  submit: {
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },
}));

function EmailForm(props){
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const savedEmail = useSelector(state => state.data.email);
  const emailFailed = useSelector(state => state.data.emailFailed);
  const participant = useSelector(state => state.data.participant);
  
  const classes = useStyles();
  if(savedEmail !== ''){
    return (
      <div className={classes.flex}>
        <Typography variant="h6" color="textPrimary">
          {emailFailed ?
            ("Email could not be sent due to a server error.")
            :
            ("Please check your email inbox to see your full report.")
          }
        </Typography>
        {emailFailed ? (
          <Typography variant="subtitle1" color="textPrimary">
            <a target="_blank" href={`https://health.thinkbluehat.com/?id=${participant.id}`}>Click here</a> to see your full report
          </Typography>
        ) : (
          <Typography variant="subtitle1" color="textPrimary">
            Thank you for taking part in our survey.
          </Typography>
        )
        }
      </div>
    )
  }

  function submitEmail() {
    dispatch(setEmailDialogOpen(true));
    dispatch(setTempEmail(email));
  }
  return (
    <div className={classes.flex}>
      <Typography variant="h6" color="textPrimary">
        To access the full report please provide your email address.
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        We will not share your email address with anyone else.
      </Typography>
      <div>
        <br />
        <Typography variant="subtitle1" color="textPrimary">
          Email
        </Typography>
        <TextField className={classes.emailField} label="Type email here..." variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <br />
      
      <div className={classes.justify}>
        <div />
        <Fab className={classes.submit} disabled={!email} variant="extended" onClick={submitEmail}>
            Submit
        </Fab>
      </div>
    </div>
  );



}

export default EmailForm;
