import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Categories from './Categories';
import BodyText from './BodyText'
import Grid from '@material-ui/core/Grid'
import './results.css'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-9),
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    width: '100%',
  },
}));

function Results(){

  const classes = useStyles();
  const theme = useTheme();
  const tabletMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <div className={classes.flex}>
          {!tabletMobile && <Categories />}
          <BodyText />
        </div>
      </Grid>
      </div>
  );
}

export default Results
